import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";

import Slide from 'react-reveal/Slide';



export default function BananaBlock() {
  return (
    <Layout pageTitle="Weaver's Cross">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Weaver's Cross - USI Projects</title>
        <meta name="description" content="We have been working as the Arts & Heritage Coordinators for a new Belfast Transport Hub opening in 2025 that will offer unprecedented access to and from Belfast."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

    <section class="text-gray-700 body-font heading-container-weavers">

    <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Weaver's Cross</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-green-900">OVERVIEW
                </h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                  We have been working as the Arts & Heritage Coordinators for a new Belfast Transport Hub opening in 
                  2025 that will offer unprecedented access to and from Belfast.<br/><br/>
                  As part of this we are developing a series of ‘cultural crumbs’ that will lead people on a trail of art 
                  and culture making use of crossings, wayfinding systems and local marks to form a tour as soon as people enter 
                  Belfast. This will help us to offer any visitors a unique Belfast Hello and connect us closer together with an authentic experience.</p>                  
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p data-scroll data-scroll-speed="0" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-gray-500 leading-relaxed mt-0"><br/>Heritage<br/>Communities<br/>Co-design<br/>Policy & Strategy<br/>Conservation<br/>Wellbeing<br/>Landscaping</p>
                  <p class="text-2xl font-bold uppercase mt-8">Services</p>
                  <p class="my-4 text-gray-500 leading-relaxed mt-0">Citizen Engagement<br/>Concept Development<br/>Illustration<br/>Policy Recomendations<br/>Creative workshops<br/>Thematics<br/>Establishing principles and frameworks</p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="w-full px-5 lg:px-12 py-5">
          <img class="w-full md:pl-20 md:pr-20"  src="/img/projects/hello-weavers.png" alt="Hello"/>
      </div>

      <div class="w-full py-12 bg-green-900">
        <div class="lg:w-4/6">
          <h3 data-scroll data-scroll-speed="2" class="text-2xl md:text-5xl font-heading px-5 md:px-20 mx-auto text-white">
            “heritage can mean different things to different people. It
            can be anything from the past that you value and want to 
            pass on to future generations”
          </h3>
        </div>
      </div>


      <div class="flex flex-wrap md:p-16 md:m-16 px-5 py-8">
        <div class="lg:w-3/6 px-5">
          <p class="text-xl md:text-3xl pb-4 md:pb-0 ">
            People and place are at the heart of everything we do here at USI, and as we embark on this exciting 
            journey together, we hope to be saying hello to you all in person very soon. In the coming weeks and 
            months we want to capture local stories, experiences, and ideas to fully harness the potential 
            of what arts & heritage can do for you and your community.
          </p>
        </div>
        <div class="lg:w-3/6 px-5">
          <Slide right>
            <img src="/img/projects/hello-self-hello-hero 1.png"/>          
          </Slide>  
        </div>
      </div>

      <div class="flex flex-wrap  md:p-16 md:m-16 px-5 py-8">
        <div class="lg:w-3/6 px-5 pb-4 md:pb-0">
          <Slide left>
            <img src="/img/projects/hello-self-hello-hero 2.png"/> 
          </Slide>           
        </div>
        <div class="lg:w-3/6 px-5">
          <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl ">
            So come say hello, tell us about yourselves and your ideas, and join us in our upcoming engagements. 
            We want you and your communities to be part of everything we do and we assure you that we are here to 
            listen, but most importantly, we want you to know that this process will be as open and transparent 
            as possible. We see this as an opportunity to work together to reimagine art and heritage as a driver for meaningful change.
          </p>
        </div>
      </div>

      <div class="w-full px-5 lg:px-12 py-5">
          <img  class="w-full md:pl-20 md:pr-20" src="/img/projects/cathal-map-holder 1 1.webp" alt="Cathal Map Holder"/>
      </div>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-green-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../ourplaceinspace" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
